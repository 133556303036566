import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Row, Col, Card, PageHeader, Typography, Button, Space, notification, Table, Input, Tag, Tooltip, Popconfirm } from "antd"
import { ClearOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import financialEntityApi from "../../../api/financialEntity"
import projectApi from "../../../api/project";
import { CreateEditFinancialEntityModal } from "./CreateEditFinancialEntityModal"
import { useDebounce } from "../../../hooks/useDebounce"
import { FinancialEntityTypeEnum } from "../../../utils/enums"

const { Text } = Typography

export const defaultTableParams = {
  pagination: {
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    size: 'small',
    position: ['bottomCenter'],
    showSizeChanger: true,
  },
  filters: {
    type: [],
    countryIds: []
  },
  search: {
    name: '',
  }
}

export const EmployeeFinancialEntityScreen = () => {
  
  const [showAddFinancialEntityModal, setShowAddFinancialEntityModal] = useState(false);
  const [currentFinancialEntity, setCurrentFinancialEntity] = useState(null)
  const [tableParams, setTableParams] = useState(defaultTableParams)
  const queryKey = ['financialEntities', { ...tableParams }]
  const queryClient = useQueryClient()
  const [searchFinancialEntity, setSearchFinancialEntity] = useState('')
  const debouncedSearchFinancialEntity = useDebounce(searchFinancialEntity, 800);
  
  useEffect(() => setTableParams( prev =>({ ...prev, pagination: { ...prev.pagination, current: 1 }, search: { name: searchFinancialEntity }})), [debouncedSearchFinancialEntity]);

  const { data: financialEntities, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { pagination, filters, search } = tableParams;
      const params = {
        page: pagination.current,
        limit: pagination.pageSize,
        financial_entity_type: filters.type,
        countryIds: filters.countryIds,
        name: search.name
      }
      const { data: { items, meta } } = await financialEntityApi.get(params)
      
      setTableParams(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: meta.totalItems,
          current: meta.currentPage,
          ...meta,
        }}))
      
      return items
    },
    onError: (error) => {
      console.log(error)
      notification.error({
        message: 'Error getting financial entities',
        duration: 3,
      })
    },
    initialData: []
  });

  const { data: countryOptions } = useQuery({
    queryKey: ['country'],
    queryFn: () => projectApi.getCountries().then(({ data }) => data.map(country => ({ value: country.id, text: country.name }))),
    onError(error) {
      console.log(error)
      notification.error({
        duration: 3,
        message: 'Error getting countries'
      })
    }
  })

  const { mutate: deleteFinancialEntity } = useMutation({
    mutationFn: id => financialEntityApi.delete(id),
    onSuccess: () => {
      notification.success({ duration: 3, message: `Financial entity deleted successfully` });
      queryClient.invalidateQueries(['financialEntities'])
    },
    onError: error => {
      console.log(error);
      notification.error({
        duration: 3,
        message: error?.response?.status === 400
          ? error.response.data?.message
          : "Error deleting financial entity",
      })
    }
  })

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
    },
    {
      title: "Comercial name",
      dataIndex: "commercial_name",
      width: 250,      
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 100,
      filteredValue: tableParams.filters.type || null,
      filters: [
        { value: FinancialEntityTypeEnum.BANK, text: 'BANK' },
        { value: FinancialEntityTypeEnum.PSP, text: 'PSP' },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Country",
      dataIndex: ['country', 'name'],
      key: 'countryIds',
      width: 150,
      filters: countryOptions,
      filterSearch: true,
      filteredValue: tableParams.filters.countryIds,
      onFilter: (value, record) => record.country.id === value,    
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: 120,
      align: 'center',
      render: active => active ? <Tag color='green'>ACTIVE</Tag> : <Tag color='volcano' >INACTIVE</Tag>
    },
    {
      title: "Actions",
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            <Tooltip title="Edit financial entity">
              <Button
                type="primary"
                size="small"
                icon={<EditOutlined />}
                onClick={() =>  editFinancialEntity(record)} 
              />
            </Tooltip>
            <Popconfirm onConfirm={ ()=> deleteFinancialEntity(record.id) } title="Are you sure to delete this financial entity?">
              <Button type="primary" size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  const clearFiltersAndSorters = () => {
    setTableParams(defaultTableParams)
    setSearchFinancialEntity('')
  }
  const editFinancialEntity = financialEntity => {
    setCurrentFinancialEntity(financialEntity);  
    setShowAddFinancialEntityModal(true);
  }

  return (
    <Row style={{ justifyContent: "center", maxWidth: '1500px', margin: '0 auto' }}>
      <Col span={24}>
        <Card style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", justifyContent: "center", padding: '20px', marginTop: '20px' }}>
          <PageHeader
            title={<Text style={{ color: '#5847B2' }}>Financial entities</Text>}
            extra={[
              <Space>
                <Button
                  key="new-financial-entity"
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={()=> setShowAddFinancialEntityModal(true)}
                  >
                  Add financial entity
                </Button>
              </Space>
            ]
            }
          />
          <Table
            title={() =>
              <Space style={{ width: '100%', justifyContent: 'flex-end', marginBottom: '15px' }}>
                  <Input
                    placeholder="Search financial entity..."
                    style={{ width: '220px' }}
                    addonAfter={searchFinancialEntity ? <CloseOutlined onClick={() => setSearchFinancialEntity('')} /> : <SearchOutlined />}
                    onChange={({ target: { value } }) => setSearchFinancialEntity(value)}
                    value={searchFinancialEntity} 
                  />
                <Button
                  disabled={Object.values(tableParams?.filters).every(filter => filter?.length === 0 || !filter)}
                  onClick={clearFiltersAndSorters}
                  icon={<ClearOutlined />}>Clear filters
                </Button>
              </Space>
            }
            columns={columns}
            loading={isLoading}
            pagination={tableParams.pagination}
            dataSource={financialEntities}
            scroll={{ x: 'max-content', y: '65vh' }}
            size="small"
            style={{ width: '100%', maxWidth: '1500px', margin: '10px auto', padding: '0 24px' }}
            onChange={(pagination, filters) => {
              setTableParams(prev => ({
                ...prev,
                pagination,
                filters: {
                  type: filters.type,
                  countryIds: filters.countryIds
                }
              }))
            }}
          />
          <CreateEditFinancialEntityModal
            open={showAddFinancialEntityModal}
            onCancel={() => {
              setShowAddFinancialEntityModal(false)
              setCurrentFinancialEntity(null)
            }} 
            currentFinancialEntity={currentFinancialEntity}
          />
        </Card>
      </Col>
    </Row>
  )
}