import api from "./api";

const prefix = "financial-entities"

export default {
  get: async (params) => {
    return api.get(prefix, { params });
  },
  create: async financialEntity => {
    return api.post(prefix, financialEntity)
  },
  update: async (id, financialEntity) => {
    return api.put(`${prefix}/${id}`, financialEntity)
  },
  getOne: async id => {
    return api.get(`${prefix}/${id}`)
  },
  delete: async id => {
    return api.delete(`${prefix}/${id}`)
  }
}   