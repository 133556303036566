import { Link } from 'react-router-dom';
import { Roles } from '../routes/Protected';
import { ExternalLink } from '../components/ExternalLink';

export const BaseNavbar = [
  {
    key: 'DASHBOARD_SCREEN',
    roles: [Roles.SUPERADMIN, Roles.SALE],
    label: (
      <Link className="nav-link" to={`/dashboard`}>
        Dashboard
      </Link>
    ),
    children: [
      {
        key: 'DASHBOARD',
        roles: [Roles.SUPERADMIN, Roles.SALE],
        label: (
          <Link className="nav-link" to={`/dashboard`}>
            Default Dashboard
          </Link>
        ),
      },
      {
        key: 'ACTIVITY_DASHBOARD',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to={`/activity-dashboard`}>
            Activity Dashboard
          </Link>
        ),
      },
    ],
  },
  {
    key: 'PROJECT_SCREEN',
    roles: [Roles.SUPERADMIN, Roles.RECRUITER, Roles.SALE],
    label: 'Client Contracts',
    children: [
      {
        key: 'CONTRACTS',
        roles: [Roles.SUPERADMIN, Roles.SALE],
        label: (
          <Link className="nav-link" to={`/contracts`}>
            All Contracts
          </Link>
        ),
      },
      {
        key: 'CUSTOMERS',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER, Roles.SALE],
        label: (
          <Link className="nav-link" to={`/customers`}>
            Customers
          </Link>
        ),
      },
    ],
  },
  {
    key: 'BILLING_SCREEN',
    roles: [Roles.SUPERADMIN, Roles.SALE],
    label: (
      <Link className="nav-link" to={`/billing`}>
        Billing
      </Link>
    ),
  },
  {
    key: 'CURRENCY_SCREEN',
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={`/currencies`}>
        Currencies
      </Link>
    ),
  },
  {
    key: 'EMPLOYEE',
    label: 'Employee',
    roles: [
      Roles.SUPERADMIN,
      Roles.RECRUITER,
      Roles.DATA_ENTRY,
      Roles.HR,
      Roles.ADMINISTRATIVE,
    ],
    children: [
      {
        key: 'EMPLOYEE_SCREEN',
        roles: [
          Roles.SUPERADMIN,
          Roles.RECRUITER,
          Roles.DATA_ENTRY,
          Roles.HR,
          Roles.ADMINISTRATIVE,
        ],
        label: (
          <Link className="nav-link" to="/employees">
            Employees
          </Link>
        ),
      },
      {
        key: 'EMPLOYEE_PAYROLL',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to="/payroll">
            Payroll
          </Link>
        ),
      },
      {
        key: 'EMPLOYEE_CONTRACT',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to="/employee-contracts">
            Contracts
          </Link>
        ),
      },
      {
        key: 'EMPLOYEE_TIMESHEET',
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
        label: 'Timesheets',
        children: [
          {
            key: 'EMPLOYEE_TIMESHEET_ALL',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/timesheets">
                All Timesheets
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_TIMESHEET_TASKS',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/timesheets/tasks">
                Tasks
              </Link>
            ),
          },
        ],
      },
      {
        key: 'EMPLOYEE_SETTING',
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE, Roles.HR],
        label: 'Settings',
        children: [
          {
            key: 'EMPLOYEE_BENEFIT_TYPE',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/benefit-types">
                Benefit types
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_SALARY_CONCEPT',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/salary-concepts">
                Salary concepts
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_JOBS',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/jobs">
                Jobs
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_FEEDBACKS',
            roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE, Roles.HR],
            label: (
              <Link className="nav-link" to="/settings/feedback-templates">
                Feedback templates
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_TECH_STACK',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/tech-stack">
                Tech Stack
              </Link>
            ),
          },
          {
            key: 'EMPLOYEE_TAGS',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/tag">
                Tags
              </Link>
            ),
          },
          {
            key: "EMPLOYEE_PAYMENT_METHODS",
            roles: [Roles.SUPERADMIN],
            label: "Payment Methods",
            children: [
              {
                key: "EMPLOYEE_PAYMENT_METHOD_TYPES",
                label: (
                  <Link className="nav-link" to="/settings/payment-method/types">
                    Types
                  </Link>
                ),
              },
              {
                key: "EMPLOYEE_PAYMENT_METHOD_FINANCIAL_ENTITIES",
                label: (
                  <Link className="nav-link" to={"/settings/payment-method/financial-entities"}>
                    Financial Entities
                  </Link>
                )
              }
            ],
          },
          {
            key: "DEPARTURE_CATEGORIES",
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to="/settings/departure-categories">
                Departure Categories
              </Link>
            ),
          }
        ],
      },
    ],
  },
  {
    key: 'RECRUITMENT',
    roles: [Roles.SUPERADMIN, Roles.RECRUITER, Roles.RECRUITER_EXTERNAL],
    label: 'Recruitment',
    children: [
      {
        key: 'RECRUITMENT_DASHBOARD',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to={`/recruitment/dashboard`}>
            Dashboard
          </Link>
        ),
      },
      {
        key: 'RECRUITMENT_APPLICANT',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER],
        label: 'Applicants',
        children: [
          {
            key: 'RECRUITMENT_APPLICANT_SEARCH',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/applicants/search`}>
                Search
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_APPLICANT_ALL',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/applicants`}>
                All Applicants
              </Link>
            ),
          },
        ],
      },
      {
        key: 'RECRUITMENT_COMMISSION',
        roles: [Roles.SUPERADMIN],
        label: 'Commissions',
        children: [
          {
            key: 'RECRUITMENT_COMMISSION_DASHBOARD',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link
                className="nav-link"
                to={`/recruitment/commission/dashboard`}
              >
                Dashboard
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_COMMISSION_ALL',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`/commissions`}>
                All Commissions
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_COMMISSION_BY_RECRUITER',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link
                className="nav-link"
                to={`/applicants/settlements-by-recruiter`}
              >
                Settlements By Recruiter
              </Link>
            ),
          },
        ],
      },
      {
        key: 'RECRUITMENT_JOB_PROPOSAL_ALL',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER],
        label: (
          <Link className="nav-link" to={`/job-proposals`}>
            Job Proposals
          </Link>
        )
      },
      {
        key: 'RECRUITMENT_JOB_POSITION',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER, Roles.RECRUITER_EXTERNAL],
        label: 'Job Positions',
        children: [
          {
            key: 'RECRUITMENT_JOB_POSITION_ALL',
            roles: [
              Roles.SUPERADMIN,
              Roles.RECRUITER,
              Roles.RECRUITER_EXTERNAL,
            ],
            label: (
              <Link className="nav-link" to={`/job-positions`}>
                All Positions
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_APPLICANT_PROCESS',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/applicant-process`}>
                All Processes
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_PIPELINE',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/pipeline-process`}>
                Kanban Pipeline
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_PUBLISHED_JOB_POSITION',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/job-positions/published`}>
                Published Positions
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_JOB_POSITION_DEPARTMENTS',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/departments`}>
                Departments
              </Link>
            ),
          },
        ],
      },
      {
        key: 'RECRUITMENT_REPORT',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER],
        label: 'Reports',
        children: [
          {
            key: 'RECRUITMENT_REPORT_NEW_APPLICANT',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/report/recruitment/applicants`}>
                New Applicants
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_REPORT_APPLICATION',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link
                className="nav-link"
                to={`/report/recruitment/applications`}
              >
                Applications
              </Link>
            ),
          },
        ],
      },
      {
        key: 'RECRUITMENT_SETTING',
        roles: [Roles.SUPERADMIN, Roles.RECRUITER],
        label: 'Settings',
        children: [
          {
            key: 'RECRUITMENT_STAGES_AND_POLICIES',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`/settings/applicant-stages`}>
                Process Stages
              </Link>
            ),
          },
          {
            key: 'COMMISSION_POLICIES',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`/settings/commission-policies`}>
                Commission Policies
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_SETTING_TAG_MATCHER',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link className="nav-link" to={`/settings/tag-matchers`}>
                Tags Matchers
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_SETTING_JOB_PROPOSAL',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`/settings/job-proposal`}>
                Job Proposal
              </Link>
            ),
          },
          {
            key: 'RECRUITMENT_PROCESS_REFUSE_CATEGORY',
            roles: [Roles.SUPERADMIN, Roles.RECRUITER],
            label: (
              <Link
                className="nav-link"
                to={`/settings/applicant-refuse-category`}
              >
                Refuse Categories
              </Link>
            ),
          },
        ],
      },
      {
        key: 'RECRUITMENT_UTILS',
        roles: [
          Roles.SUPERADMIN,
          Roles.RECRUITER,
          Roles.ADMINISTRATIVE,
          Roles.RECRUITER_EXTERNAL,
        ],
        label: 'Utils',
        children: [
          {
            key: 'RECRUITMENT_UTILS_CHATGPT_RESUME',
            roles: [
              Roles.SUPERADMIN,
              Roles.RECRUITER,
              Roles.ADMINISTRATIVE,
              Roles.RECRUITER_EXTERNAL,
            ],
            label: (
              <Link className="nav-link" to={`/utils/chatgpt-resume`}>
                ChatGPT Resume
              </Link>
            ),
          },
        ],
      },
    ],
  },
  {
    key: 'REPORT',
    roles: [Roles.SUPERADMIN, Roles.RECRUITER, Roles.HR, Roles.SALE],
    label: 'Reports',
    children: [
      {
        key: 'REPORT_HOUR',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to={`/report/hour`}>
            Hour
          </Link>
        ),
      },
      {
        key: 'REPORT_ASSIGNMENTS',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to="/report/assignments">
            Assignments
          </Link>
        ),
      },
      {
        key: 'REPORT_ASSESSMENTS',
        roles: [Roles.SUPERADMIN, Roles.HR],
        label: (
          <Link className="nav-link" to="/report/employee-assessments">
            Employee Assessments
          </Link>
        ),
      },
      {
        key: 'REPORT_SALARY_CONCEPTS',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to="/report/salary-concepts">
            Salary concepts
          </Link>
        ),
      },
      {
        key: 'REPORT_CLIENT_CONTRACTS',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.SALE],
        label: 'Client Contracts',
        children: [
          {
            key: 'REPORT_CLIENT_CONTRACTS_BY_DM',
            roles: [Roles.SUPERADMIN, Roles.HR, Roles.SALE],
            label: (
              <Link
                className="nav-link"
                to="/report/client-contracts/by-delivery-manager"
              >
                By Delivery Manager
              </Link>
            ),
          },
        ],
      },
      {
        key: 'REPORT_FORECAST',
        roles: [Roles.SUPERADMIN, Roles.SALE],
        label: 'Forecast',
        children: [
          {
            key: 'REPORT_FORECAST_BILLING',
            roles: [Roles.SUPERADMIN, Roles.SALE],
            label: (
              <Link className="nav-link" to={`/report/forecast/billing`}>
                Billing
              </Link>
            ),
          },
          {
            key: 'REPORT_FORECAST_FINANCIAL',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`/report/forecast/financial`}>
                Financial
              </Link>
            ),
          },
        ],
      },
      {
        key: 'REPORT_TIMESHEETS',
        roles: [Roles.SUPERADMIN],
        label: (
          <Link className="nav-link" to="/report/timesheets">
            Timesheets
          </Link>
        ),
      },
      // {
      //   key: 'REPORT_RECRUITMENT',
      //   roles: [Roles.SUPERADMIN, Roles.RECRUITER],
      //   label: 'Recruitment',
      //   children: [
      //     {
      //       key: 'REPORT_RECRUITMENT_GOALS',
      //       roles: [Roles.SUPERADMIN, Roles.RECRUITER],
      //       label: <Link className='nav-link' to={`/report/recruitment/goals`} >Goals by recruiter</Link>,
      //     }
      //   ]
      // }
    ],
  },
  {
    key: 'TREE_DONATION',
    roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE, Roles.HR],
    label: (
      <Link className="nav-link" to="/tree-donation">
        Tree Donations
      </Link>
    ),
  },
  {
    key: 'BUSINESS_PROCESS_SCREEN',
    roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
    label: 'Business Process',
    children: [
      {
        key: 'MY_TASKS_SCREEN',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/tasks/me`}>
            My Tasks
          </Link>
        ),
      },
      {
        key: 'ONBOARDING_SCREEN',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/onboardings`}>
          Onboardings
        </Link>
        ),
      },
      {
        key: 'OFFBOARDING_SCREEN',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/offboardings`}>
            Offboardings
          </Link>
        ),
      },
      {
        key: 'PROJECT_ONBOARDING_SCREEN',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/project-onboardings`}>
            Client Contract Onboarding
          </Link>
        ),
      },
      {
        key: 'PROJECT_OFFBOARDING_SCREEN',
        roles: [Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/project-offboardings`}>
            Client Contract Offboarding
          </Link>
        ),
      },
    ]
  },
  {
    key: "SALES_COMMISSION",
    roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
    label: "Sales Commission",
    children: [
      {
        key: "POLICIES",
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`sales-commission/policies`}>Policies</Link>
        )
      },
      {
        key: "COMMISSIONS",
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`sales-commission/commissions`}>Commissions</Link>
        )
      }
    ]
  },
  {
    key: 'SETTING',
    roles: [Roles.SUPERADMIN],
    label: 'Settings',
    children: [
      {
        key: 'SETTING_TASK_TEMPLATES',
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
        label: (
          <Link className="nav-link" to={`/task-templates`}>
            Task Templates
          </Link>
        ),
      },
      {
        key: 'SETTING_CONTACTS',
        roles: [Roles.SUPERADMIN],
        label: 'Contacts',
        children: [
          {
            key: 'SETTING_CONTACT',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`settings/contacts`}>
                Contacts
              </Link>
            ),
          },
          {
            key: 'SETTING_CONTACT_ROLE',
            roles: [Roles.SUPERADMIN],
            label: (
              <Link className="nav-link" to={`settings/contact-roles`}>
                Roles
              </Link>
            ),
          },
        ],
      },
      {
        key: 'SETTING_CALENDARS',
        roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
        label: 'Calendars',
        children: [
          {
            key: 'SETTING_CALENDAR',
            roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
            label: (
              <Link className="nav-link" to={'calendar'}>
                Calendar
              </Link>
            ),
          },
          {
            key: 'SETTING_CALENDAR_TEMPLATE',
            roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
            label: (
              <Link className="nav-link" to={'calendar/template-days'}>
                Template Days
              </Link>
            ),
          },
        ],
      },
    ],
  },
  {
    key: 'ASSETS',
    label: (
      <a
        href={`${process.env.REACT_APP_BASE_URL_ASSET_APP}`}
        target="_blank"
        className="nav-link"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        Asset App
        <ExternalLink style={{ marginLeft: '5px' }} />
      </a>
    ),
    roles: [Roles.SUPERADMIN, Roles.ADMINISTRATIVE],
  },
];
