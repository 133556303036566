import React from 'react';
import { Headers } from './Headers';
import { Layout } from 'antd';


const { Content, Footer } = Layout;

export const AppLayout = ({ children }) =>
  <Layout style={{ height: '100%', textAlign: 'center' }}>
    <Headers />
    <Content style={{ marginTop: '64px', padding: '16px', minHeight: 'calc(100vh - 134px)' }}>
      {children}
    </Content>
    <Footer>Exo App ©2022</Footer>
  </Layout>