import React from 'react';
import 'antd/dist/antd.css';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';


export const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to="/">
        <Button icon={<ArrowLeftOutlined />} type="primary">Back to home</Button>
      </Link>
    }
  />
)