import React from "react";
import { Col, Result, Row, Spin } from "antd";


export const InitializingApp = () =>
  <Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
    <Col span={24} align="center">
      <Result
        icon={<Spin />}
        title="Initializing ExoApp..." />
    </Col>
  </Row>