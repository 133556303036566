import axios from "axios";
import { getStorageItem } from "../helpers/local-storage";


const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BACKEND,
});

const onRequestFulfilled = config => {
  const tokens = getStorageItem('KC_TOKEN');
  if (tokens) {
    const token = JSON.parse(tokens).token;
    config.headers.Authorization = `Bearer ${token}`;
  } else config.headers.Authorization = '';
  return config;
}

const onRequestError = error => Promise.reject(error);

const onReponseError = async error => {
  if (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        error.response.data = JSON.parse(reader.result);
        resolve(Promise.reject(error));
      };
      reader.onerror = () => {
        reader.abort();
        reject(error);
      };
      reader.readAsText(error.response.data);
    });
  };

  return Promise.reject(error);
}

instance.interceptors.request.use(onRequestFulfilled, onRequestError);
instance.interceptors.response.use(response => response, onReponseError);

export default instance;