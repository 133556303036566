import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { ForbiddenScreen } from "../pages/Forbidden/Forbidden";


export const RolePolicy = {
  MATCH_ANY: 'MATCH_ANY',
  MATCH_ALL: 'MATCH_ALL',
}

export const Roles = {
  SUPERADMIN: 'superadmin',
  RECRUITER: 'recruiter',
  RECRUITER_EXTERNAL: 'recruiter-external',
  HR: 'hr',
  DATA_ENTRY: 'data-entry',
  ADMINISTRATIVE: 'administrative',
  SALE: 'sale',
  HR_COMMISSIONS_APPROVER: 'hr-commissions-approver'
}

export const Protected = ({ roles, children, policy = RolePolicy.MATCH_ALL, route = false }) => {

  const { keycloak: { token, authenticated, login } } = useKeycloak();
  if (!authenticated || !token) login();

  const payload = decodeJwt(token);

  if (!roles || roles.length === 0) return children;
  
  const { realm_access } = payload;

  if (!realm_access?.roles) return route ? <ForbiddenScreen /> : <></>;

  let hasPermission = false;
  if (policy === RolePolicy.MATCH_ANY) hasPermission = roles?.some(role => realm_access.roles.includes(role));
  if (policy === RolePolicy.MATCH_ALL) hasPermission = roles?.every(role => realm_access.roles.includes(role));
  
  if (hasPermission) return children;
  return route ? <ForbiddenScreen /> : <></>;
}