import api from "./api";

const prefix = "project";
const prefixTasks = "project-tasks";

const projectApi = {
  getAllProjects: function (deliveryManager) {
    return api.get(`${prefix}`, { params: { deliveryManager } });
  },
  getEnabledProjects: function (from, to) {
    return api.get(`${prefix}/enabled`, { params: { from, to } });
  },
  findOneProject: function (id) {
    return api.get(`${prefix}/${id}`);
  },
  createProject: function (project) {
    return api.post(`${prefix}`, project);
  },
  editProject: function (id, project) {
    return api.put(`${prefix}/${id}`, project);
  },
  deleteProject: function (id) {
    return api.delete(`${prefix}/${id}`);
  },
  getCountries: function () {
    return api.get(`${prefix}/get/countries`);
  },
  getCompanies: function () {
    return api.get(`${prefix}/get/companies`);
  },
  getProjectsWithoutInvoice: function (year, month) {
    return api.get(`${prefix}/get/withoutInvoice/${year}/${month}`);
  },
  getLastBilledMomentPerProject: function (idProject) {
    return api.get(`${prefix}/get/lastDateTo/${idProject}`);
  },
  getProjectNames: function (params) {
    return api.get(`${prefix}/names`, { params });
  },
  getProjectContactNames: function (client) {
    return api.get(`${prefix}/contact-names`, { params: { client } });
  },
  getDeliveryManager: function (projectId) {
    return api.get(`${prefix}/get/deliveryManager/${projectId}`);
  },
  getLateInvoiceProjects: function () {
    return api.get(`project/late-invoice`);
  },
  addContact: function (projectId, contact) {
    return api.post(`${prefix}/${projectId}/contact`, contact);
  },
  removeContact: function (projectContactId) {
    return api.delete(`${prefix}/contact/${projectContactId}`);
  },
  getProjectsAndTasks: async (employeeId, date) => {
    return api.get(`project/projects-and-tasks/${employeeId}`, { params: { date } });
  },
  getTasks: async (params) => {
    return api.get(prefixTasks, { params });
  },
  getTaskNames: async () => {
    return api.get(`${prefixTasks}/names`);
  },
  createTask: async (data, params) => {
    return api.post(prefixTasks, data, { params });
  },
  updateTask: async (taskId, data, params) => {
    return api.put(`${prefixTasks}/${taskId}`, data, { params });
  },
  deleteTask: async (taskId) => {
    return api.delete(`${prefixTasks}/${taskId}`);
  },
  search: function (query, active) {
    return api.get(`${prefix}/search`, { params: { query, active } });
  }
};

export const searchProjects = (query) => projectApi.search(query, true)
  .then(({ data }) =>
    data.map(({ id, name }) => ({ label: name, value: id })
    ));

export default projectApi;