import { Modal, Form, Input, Select, Switch, notification } from "antd"
import financialEntityApi from "../../../api/financialEntity";
import { useQuery, useQueryClient } from "react-query";
import { FinancialEntityTypeEnum } from "../../../utils/enums";
import projectApi from "../../../api/project";
import { useEffect } from "react";

const { Item } = Form;

const financialTypeOptions = [
  { label: FinancialEntityTypeEnum.BANK, value: FinancialEntityTypeEnum.BANK },
  { label: FinancialEntityTypeEnum.PSP, value: FinancialEntityTypeEnum.PSP }
]

export const CreateEditFinancialEntityModal = ({ open, onCancel, currentFinancialEntity }) => {
  
  const [form] = Form.useForm();
  const queryClient = useQueryClient()

  useEffect(() => currentFinancialEntity && form.setFieldsValue({ ...currentFinancialEntity, country_id: currentFinancialEntity?.country?.id }), [currentFinancialEntity])

  const { data: countryOptions } = useQuery({
    queryKey: ['countries'],
    queryFn: async () => {
      const { data: countries } = await projectApi.getCountries()
      return countries.map(country => ({ label: country.name, value: country.id }))
    },
    onError: (error) => {
      console.log(error)
      notification.error({
        message: 'Error getting countries',
        duration: 3,
      })
    },
    initialData: []
  })

  const onFinish = async financialEntity => {
    try {
      if (currentFinancialEntity) await financialEntityApi.update(currentFinancialEntity.id, financialEntity)
      else await financialEntityApi.create(financialEntity)
      queryClient.invalidateQueries(['financialEntities'])
      notification.success({
        duration: 3,
        message: `Financial entity ${currentFinancialEntity ? 'updated': 'created'} successfully`
      })
      onCancel()
    } catch (error) {
      console.log(error)
      notification.error({
        duration: 3,
        message: `Error ${currentFinancialEntity ? 'updating' : 'creating'} the financial entity`
      })
    }
  }
  
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={`${currentFinancialEntity ? 'Edit': 'Create'} financial entity`}
      onOk={() => form.submit()}
      destroyOnClose
      okText="Save"
      cancelText="Close"
      afterClose={() => form.resetFields()}
      style={{
        top: '20px'
      }}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
        <Item label="Name" name="name" rules={[{ required: true, min: 2, max: 255 }]} >
          <Input placeholder="Financial entity name"/>
        </Item>
        <Item label="Type" name="type" rules={[{ required: true }]}>
          <Select options={financialTypeOptions} placeholder="Type" />
        </Item>
        <Item label="Commercial name" name="commercial_name" rules={[{ required: true, min: 2, max: 255 }]}>
          <Input placeholder="Commercial name"/>
        </Item>
        <Item label="Code" name="code" rules={[{ required: true, max: 255 }]}>
          <Input placeholder="Code"/>
        </Item>
        <Item label="Country" name="country_id" rules={[{ required: true  }]}>
          <Select options={countryOptions} placeholder="Country" />
        </Item>
        {
          currentFinancialEntity &&
            <Item label="Active" name="active" valuePropName="checked" > 
              <Switch />
            </Item>
        }
      </Form>
    </Modal>
  )
}